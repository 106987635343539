
/**
 * Class representing a Mgallery Callout Container Component instance
 * @author Rishabh
 * @class MGalleryCalloutContainer
 * @classdesc Mgallery callout container component to inherit the callout container core component
 * @extends CalloutContainer
 * @version 1.0
 */
import { CalloutContainer, TagManager } from "@accor/ace-ui-core"

export default class MGalleryCalloutContainer extends CalloutContainer {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost)
    const dataLayerValue = document.querySelector("[data-cmp-datalayer]")?.getAttribute("data-cmp-datalayer")
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue)
    }

    this.dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
      },
    }

    this.calloutContainerItem = this.componentHost.querySelectorAll(".callout-section ul li")
    this.calloutContainerCTA = this.componentHost.querySelector(".ace-loyalty__inner")
    this.pageHeadingTitle = document
      .querySelector('.heading .ace-headingpagehero-headline [class*="expressive-heading"]')
      ?.innerText?.trim()
      ?.toLowerCase()

    this.calloutTagManagerHandle()
    this.calloutCtaTagManagerHandle()
    this.callCalloutBookingEngine()
  }

  /**
   * push ga events for callouts in tag manager depending on the page
   */
  calloutTagManagerHandle() {
    if (this.dataLayerJson) {
      this.calloutContainerItem?.forEach((element, index) => {
        element?.querySelector(".ace-callout-component")?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const calloutTitle = element?.querySelector(".cmp-teaser__pretitle")?.innerText?.trim()?.toLowerCase()
            ? element?.querySelector(".cmp-teaser__pretitle")?.innerText?.trim()?.toLowerCase()
            : ""
          const calloutKicker = element?.querySelector(".cmp-teaser__kicker")?.innerText?.trim()?.toLowerCase()
            ? element?.querySelector(".cmp-teaser__kicker")?.innerText?.trim()?.toLowerCase()
            : ""
          const calloutHotelId = element.querySelector(".ace-callout-component")?.getAttribute("data-product-id")
            ? element.querySelector(".ace-callout-component")?.getAttribute("data-product-id")
            : calloutTitle
          if (this.dataEvent && this.dataLayerJson?.pageName === "mgallery::brand::homepage") {
            // Homepage Template
            this.dataEvent.event_data.bloc_type = `${calloutTitle} | ${index + 1}`
            if (this.componentHost?.parentElement?.classList?.contains("card-container")) {
              this.dataEvent.event_data.bloc_name = "hotels highlight"
              this.dataEvent.event_data.bloc_interaction = "discover hotel page"
            } else {
              this.dataEvent.event_data.bloc_name = "news"
              this.dataEvent.event_data.bloc_interaction = "see news page"
            }
          } else if (this.dataLayerJson?.pageName === "mgallery::destinations::world") {
            // Destination template
            if (this.componentHost?.parentElement?.classList?.contains("card-container")) {
              this.dataEvent.event_data.bloc_name = "destinations"
              this.dataEvent.event_data.bloc_interaction = "see destination page"
              this.dataEvent.event_data.bloc_type = `${calloutTitle} - ${calloutKicker} | ${index + 1}`
            } else {
              this.dataEvent.event_data.bloc_name = "hotels push"
              this.dataEvent.event_data.bloc_interaction = "see hotel page"
              this.dataEvent.event_data.bloc_type = `${calloutHotelId} | ${index + 1}`
            }
          } else if (this.dataLayerJson?.pageName === "mgallery::offers::homepage") {
            // Offers home template
            this.dataEvent.event_data.bloc_name = "offers"
            this.dataEvent.event_data.bloc_interaction = "see offer page"
            this.dataEvent.event_data.bloc_type = `${calloutTitle} | ${index + 1}`
          } else if (this.dataLayerJson?.pageName === "mgallery::offers::details") {
            // Offer detail template
            if (this.componentHost?.parentElement?.classList?.contains("callout-container-api")) {
              this.dataEvent.event_data.bloc_name = "hotels push"
              this.dataEvent.event_data.bloc_interaction = "see hotel page"
              this.dataEvent.event_data.bloc_type = `${calloutHotelId} | ${index + 1}`
            } else {
              this.dataEvent.event_data.bloc_name = "other offers"
              this.dataEvent.event_data.bloc_interaction = "see offer page"
              this.dataEvent.event_data.bloc_type = `${calloutTitle} | ${index + 1}`
            }
          } else if (this.dataLayerJson?.pageName === "mgallery::mgalleryuniverse::memorable") {
            // Memorable
            this.dataEvent.event_data.bloc_name = "mgallery universe"
            this.dataEvent.event_data.bloc_interaction = "see universe"
            this.dataEvent.event_data.bloc_type = index + 1
          } else if (this.dataLayerJson?.pageName === "mgallery::mgalleryuniverse::meaningful") {
            // Meaningful
            this.dataEvent.event_data.bloc_name = "mgallery universe"
            this.dataEvent.event_data.bloc_interaction = "see memorable"
            this.dataEvent.event_data.bloc_type = index + 1
          } else if (this.dataLayerJson?.pageName === "mgallery::mgalleryuniverse::memorable::mixology") {
            // Mixology template
            if (this.componentHost?.parentElement?.classList?.contains("card-container")) {
              this.dataEvent.event_data.bloc_name = "hotels highlighted"
              this.dataEvent.event_data.bloc_interaction = "see hotel page"
              this.dataEvent.event_data.bloc_type = `${calloutTitle} | ${index + 1}`
            } else {
              this.dataEvent.event_data.bloc_name = "mgallery universe"
              this.dataEvent.event_data.bloc_interaction = "see memorable"
              this.dataEvent.event_data.bloc_type = index + 1
            }
          } else if (this.dataLayerJson?.pageName === "mgallery::mgalleryuniverse::memorable::design") {
            // Design template
            if (this.componentHost?.querySelector(".ace-carousel-component-v2")) {
              this.dataEvent.event_data.bloc_name = `hotels push - ${this.componentHost.parentElement.previousElementSibling.querySelector("div")?.getAttribute("id")}`
              this.dataEvent.event_data.bloc_interaction = "see hotel page"
              this.dataEvent.event_data.bloc_type = `${calloutHotelId} | ${index + 1}`
            } else {
              this.dataEvent.event_data.bloc_name = "mgallery universe"
              this.dataEvent.event_data.bloc_interaction = "see memorable"
              this.dataEvent.event_data.bloc_type = index + 1
            }
          } else if (this.dataLayerJson?.pageName === "mgallery::mgalleryuniverse::memorable::mmoments") {
            // Mmoments template
            this.dataEvent.event_data.bloc_name = `editorial - ${this.componentHost.parentElement.previousElementSibling.querySelector("div")?.getAttribute("id")}`
            this.dataEvent.event_data.bloc_interaction = "see editorial page"
            this.dataEvent.event_data.bloc_type = index + 1
          } else {
            this.dataEvent.event_data.bloc_name = "mgallery universe"
            this.dataEvent.event_data.bloc_interaction = "see memorable"
            this.dataEvent.event_data.bloc_type = index + 1
          }
          TagManager.trackEvent(this.dataEvent)
        })
      })
    }
  }

  /**
   * push ga events for callout container ctas in tag manager depending on the page
   */
  calloutCtaTagManagerHandle() {
    this.calloutContainerCTA?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      delete this.dataEvent.event_data.bloc_type
      if (
        (this.dataEvent && this.dataLayerJson?.pageName === "mgallery::destinations::world") ||
        this.dataLayerJson?.pageName === "mgallery::offers::details"
      ) {
        // Destination Template / offer detail
        this.dataEvent.event_data.bloc_name = "hotels push"
        this.dataEvent.event_data.bloc_interaction = "see more hotels"
      } else if (this.dataLayerJson?.pageName === "mgallery::offers::homepage") {
        // Offers home template
        this.dataEvent.event_data.bloc_name = "offers"
        this.dataEvent.event_data.bloc_interaction = "see more offers"
      } else if (this.dataLayerJson?.pageName === "mgallery::mgalleryuniverse::memorable::mmoments") {
        // Mmoments
        this.dataEvent.event_data.bloc_name = `editorial - ${this.componentHost.parentElement.previousElementSibling.querySelector("div")?.getAttribute("id")}`
        this.dataEvent.event_data.bloc_interaction = "see more editorials"
      } else {
        this.dataEvent.event_data.bloc_name = "view more"
      }
      TagManager.trackEvent(this.dataEvent)
    })
  }

  updateFormBookingeEngineValues(id = null, value = "") {
    if (!id || value == "") {
      return
    }

    if (document.querySelector('.ace-core-booking-engine input[name="search.destination"]')) {
      document.querySelector('.ace-core-booking-engine input[name="search.destination"]').value = id
    }
    if (document.querySelector(".ace-core-booking-engine #search-destination input")) {
      document.querySelector(".ace-core-booking-engine #search-destination input").value = value
    }

    // scroll to form
  }

  getDestinationId(element = null) {
    if (!element) {
      return
    }

    const closetElement = element.closest(".ace-callout-component")

    if (closetElement.dataset.productId) {
      return closetElement.dataset.productId
    }
  }

  callCalloutBookingEngine() {
    if (document.querySelectorAll(".ace-callout-booking-engine-prefilled").length) {
      this.componentHost?.querySelectorAll(".ace-callout-booking-engine-prefilled").forEach((element) => {
        element.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          // get text + id
          event.preventDefault()
          this.updateFormBookingeEngineValues(this.getDestinationId(event.target), event.target.innerText || "")
          // update booking engine form
          if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            document
              .querySelector(".immersive-heading, .sticky-booking-engine, .booking-engine-container")
              .scrollIntoView({ behavior: "smooth", block: "end" })
          } else {
            if (document.querySelector(".sticky-booking-engine")) {
              document.querySelector(".sticky-booking-engine .searching").click()
            } else {
              document.querySelector(".booking-engine-container").scrollIntoView({ behavior: "smooth", block: "end" })
            }
          }
          document.querySelector(".ace-core-booking-engine [data-date='checkin']").show()
        })
      })
    }
  }
}

// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(CalloutContainer.CLASS_NAMESPACE, MGalleryCalloutContainer, true)
