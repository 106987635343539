/* eslint-disable no-unused-expressions */
/**
 * Class representing a MGallery Quick Access Component instance
 * @author Rishabh
 * @class MGalleryQuickAccessV2
 * @classdesc Mgallery quick access component V2 to inherit the quick access core component
 * @extends QuickAccessV2
 * @version 1.0
 */
import { QuickAccessV2, TagManager } from '@accor/ace-ui-core';

export default class MGalleryQuickAccessV2 extends QuickAccessV2 {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.quickAccessNavItems = this.componentHost.querySelectorAll('.cmp-ace-anchor-navigation__tabs__anchors ul li');
    this.tagManagerHandler();
  }

  tagManagerHandler() {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      let blockName = '';
      if (this.dataLayerJson?.pageName === 'mgallery::destinations::world') { // Destination
        blockName = 'continent localisation';
      } else if (this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::memorable::design') { // MGallery universe memorable design
        blockName = 'design menu';
      } else if (this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::memorable::mmoments') { // MGallery universe M Moments
        blockName = 'thematic menu';
      }
      this.quickAccessNavItems?.forEach((element, index) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: 'GA4event',
            eventName: 'menu_inpage',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: blockName,
              menu_position: index + 1,
              menu_label: element?.innerText?.trim()?.toLowerCase()
            }
          };
          TagManager.trackEvent(dataEvent);
        });
      });
    }
  }
}


// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(QuickAccessV2.CLASS_NAMESPACE, MGalleryQuickAccessV2, true);
