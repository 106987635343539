// Stylesheets
import './theme.scss';
import './resources/images/favicon.svg';

// ACE Library JS
import "@accor/ace-ui-core";

// Import here all JS overlays that are overrigind ace-ui-core library. By default, the full
// original lib is imported

// COMMON JS Components
//import './components/common/book-now/v1/js/book-now.js';

import './components/common/navigation-header/v1/js/navigation-header.js';
import './components/common/immersive-heading/v1/js/immersive-heading.js';
import './components/common/special-touches/v1/special-touches/js/special-touches.js';
import './components/common/callouts/v1/callout/js/callout.js';
import './components/common/callouts/v1/callout-container/js/callout-container.js';
import './components/common/chip/v1/js/chip.js';
import './components/common/core-booking-engine/v1/js/core-booking-engine.js';
import './components/common/quick-access/v2/js/quick-access-v2.js';
import './components/common/sticky-booking-engine/v1/js/sticky-booking-engine.js';

// CONTENT JS Components
//import './components/content/billboard/v1/js/billboard.js';
import './components/content/card-editorial-slideshow/v1/js/card-editorial-slideshow.js'
import './components/content/slideshow-carousel/v1/js/slideshow-carousel.js';


// STRUCTURE JS Components
import './components/structure/base-page-template/v1/js/base-page-template.js';

document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT));
