/**
 * @author Jonas.Fournel
 */
import {BasePageTemplate} from "@accor/ace-ui-core";

export default class MGALLERYBasePageTemplate extends BasePageTemplate {

    /** @inheritDoc */
    constructor(componentHost) {
        super(componentHost);
        const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
        if (dataLayerValue) {
            this.dataLayerJson = JSON.parse(dataLayerValue);
            this.basePageTagManagerPush();
        }
    }

    basePageTagManagerPush() {
        // For Highlight tag manager push on various templates
        if (this.dataLayerJson?.pageName === 'mgallery::brand::homepage' || this.dataLayerJson?.pageName === 'mgallery::offers::homepage' || this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::meaningful'
        || this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::meaningful::commitedtoher' || this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::meaningful::givebacktocommunity' || this.dataLayerJson?.pageName === 'mgallery::brand::loyalty'
        || this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::memorable::mindfulness' || this.dataLayerJson?.pageName === 'mgallery::hotel::homepage') {
            document?.querySelectorAll('.highlight .ace-highlight .ace-highlight__content .ace-button a')?.forEach(( button, index ) => {
                button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
                    this.pushHighlightTagmanager(event, index);
                });
            });
        }

        // For Short-title tag manager push on various templates
        if (this.dataLayerJson?.pageName === 'mgallery::brand::homepage' || this.dataLayerJson?.pageName === 'mgallery::offers::details' || this.dataLayerJson?.pageName === 'mgallery::destinations::world' || this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::meaningful') {
            document?.querySelectorAll('.title .ace-shorttitle .ace-shorttitle__link a')?.forEach(( button ) => {
                button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
                    this.pushShortTitleTagmanager(event);
                });
            });
        }

        // For social icon list tag manager push on various templates
        if (this.dataLayerJson?.pageName === 'mgallery::brand::homepage') {
            document?.querySelectorAll('.iconlist .ace-footer-icon__list .ace-footer-icon__list--item--link')?.forEach(( button ) => {
                button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
                    this.pushSocialIconListTagmanager(event);
                });
            });
        }

        // For billboard with image tag manager push
        document?.querySelectorAll('.billboard .ace-billboard-component .ace-billboard__withimage .ace-billboard__fullwidth__button a')?.forEach(( button ) => {
            button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
                this.billboardWithImageTagmanager(event);
            });
        });

        // For billboard full_width and full_grid tag manager push
        document?.querySelectorAll('.billboard [class*="ace-billboard__full"].single-button a')?.forEach(( button ) => {
            button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
                this.billboardTagmanager(event);
            });
        });

        // For CTA button
        const buttonCTA = document?.querySelectorAll('#main-content .button');
        buttonCTA?.forEach(( buton ) => {
          if (buton?.previousElementSibling?.classList?.contains('highlight') || buton?.previousElementSibling?.classList?.contains('paragraph')) {
            buton?.querySelectorAll('.ace-button a')?.forEach(( anchor ) => {
              anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
                this.pushCTATagmanager(event);
              });
            });
          }
        });
    }

    /**
   * Pushing Highlight cta data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   * @param {string} index The target element index on the page
   */
    pushHighlightTagmanager(event, index) {
        const dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: 'editorial highlight',
                bloc_interaction: 'discover now'
            }
        };
        if (this.dataLayerJson?.pageName === 'mgallery::offers::homepage') { // Offers homepage
            dataEvent.event_data.bloc_name = `content ${event?.target?.closest('.ace-highlight__content')?.querySelector('.ace-highlight__content--title-mosaique')?.innerText?.trim()?.toLowerCase()}` ;
        } else if (this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::meaningful') { // Meaningful
            dataEvent.event_data.bloc_name = 'highlighted meaningful';
            dataEvent.event_data.bloc_interaction = `see ${event?.target?.closest('.ace-highlight__content')?.querySelector('.ace-highlight__content--title')?.innerText?.trim()?.toLowerCase()}`;
        } else if (this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::meaningful::commitedtoher' || this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::meaningful::givebacktocommunity') { // Meaningful commited to her
            dataEvent.event_data.bloc_name = 'meaningful article';
            dataEvent.event_data.bloc_interaction = 'see details';
            dataEvent.event_data.bloc_type = index + 1;
        } else if (this.dataLayerJson?.pageName === 'mgallery::hotel::homepage') { // Meaningful give back to community
            dataEvent.event_data.bloc_name = `content ${event?.target?.innerText?.trim()?.toLowerCase()}`;
            dataEvent.event_data.bloc_interaction = 'discover';
        } else if (this.dataLayerJson?.pageName === 'mgallery::brand::loyalty') { // Loyalty
            dataEvent.eventName = 'cta_enroll';
            dataEvent.event_data.enroll_context = 'loyalty';
            dataEvent.event_data.cta_name = event?.target?.innerText?.trim()?.toLowerCase();
        } else if (this.dataLayerJson?.pageName === 'mgallery::mgalleryuniverse::memorable::mindfulness') { // mindfulness
            dataEvent.event_data.bloc_name = 'mindfulness article';
            dataEvent.event_data.bloc_interaction = 'see details';
            dataEvent.event_data.bloc_type = index + 1;
        }
        TagManager.trackEvent(dataEvent);
    }

    /**
   * Pushing Title cta data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
    pushShortTitleTagmanager(event) {
        const dataEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
                pagename: this.dataLayerJson?.pageName,
                bloc_name: 'offers',
                bloc_interaction: event?.target?.innerText?.trim()?.toLowerCase()
            }
        };
        if (this.dataLayerJson?.pageName === 'mgallery::destinations::world') { // Destination
            dataEvent.event_data.bloc_name = 'world map';
            dataEvent.event_data.bloc_interaction = 'see all hotels';
        } else if (this.dataLayerJson?.pageName === 'mgallery::offers::details') { // Offers details
            dataEvent.event_data.bloc_name = 'other offers';
        } else if (this.dataLayerJson?.pageName === 'mgallery::brand::homepage') { // homepage
            if (event?.target?.closest('.section-background-container')) {
              dataEvent.event_data.bloc_name = 'hotels highlight';
            }
        }
        TagManager.trackEvent(dataEvent);
    }

    /**
   * Pushing Social icon list data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
    pushSocialIconListTagmanager(event) {
      const dataEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          bloc_name: 'social',
          bloc_interaction: event?.target?.getAttribute('alt')
        }
      };
      TagManager.trackEvent(dataEvent);
    }

    /**
   * Pushing billboard with image data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
    billboardWithImageTagmanager(event) {
      const dataEvent = {
        event: 'GA4event',
        eventName: 'cta_enroll',
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          enroll_context: 'loyalty',
          cta_name: 'become a member'
        }
      };
      TagManager.trackEvent(dataEvent);
    }

    /**
   * Pushing billboard data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
    billboardTagmanager(event) {
      const dataEvent = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pagename: this.dataLayerJson?.pageName,
          bloc_name: 'newsletter',
          bloc_interaction: 'open newsletter form'
        }
      };
      TagManager.trackEvent(dataEvent);
    }

    /**
   * Pushing CTA data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
    pushCTATagmanager(event) {
        const dataEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.dataLayerJson?.pageName,
            enroll_context: 'loyalty',
            cta_name: 'become a member'
          }
        };
        if (this.dataLayerJson?.pageName === 'mgallery::hotel::homepage') {
          dataEvent.event_data = {
            pagename: this.dataLayerJson?.pageName,
            bloc_name: 'hotel information',
            bloc_interaction: 'book now'
          }
        } else if (this.dataLayerJson?.pageName === 'mgallery::brand::loyalty') {
            dataEvent.eventName = 'cta_enroll';
        }
        TagManager.trackEvent(dataEvent);
      }
}

CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, MGALLERYBasePageTemplate, true);
