/* eslint-disable no-unused-expressions */
/**
 * Class representing a Mgalley Chip Component instance
 * @author Rishabh
 * @class MGalleryChip
 * @classdesc Mgallery Chip component to inherit the Chip core component
 * @extends Chip
 * @version 1.0
 */
import { Chip, TagManager } from '@accor/ace-ui-core';

export default class MGalleryChip extends Chip {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.tagManagerHandler();
  }

  tagManagerHandler() {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const filterName = 'country';
      const btns = this.componentHost?.querySelectorAll('.ace-chip__list .ace-chip--button');
      btns?.forEach((btn) => btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        let eventName = 'remove_filter';
        if (btn?.classList?.contains('active')) { // reverse toggle as active class set is executed on core
          eventName = 'apply_filter';
        };
        const dataEvent = {
          event: 'GA4event',
          eventName: eventName,
          event_data: {
            pagename: this.dataLayerJson?.pageName,
            filter_label: btn?.querySelector('.ace-chip--title')?.innerText?.trim()?.toLowerCase(),
            filter_name: filterName
          }
        };
        TagManager.trackEvent(dataEvent);
      }));
    }
  }
}


// Register the MGalleryChip component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Chip.CLASS_NAMESPACE, MGalleryChip, true);
