import { HeaderNavigation } from '@accor/ace-ui-core';

export default class MGalleryHeaderNavigation extends HeaderNavigation {
    static CLASS_NAMESPACE = 'ace-header-navigation';

    /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.HeadElement = document.querySelector("main > div > div").firstElementChild;
    
    if(this.HeadElement.classList.contains('immersive-heading') || this.HeadElement.classList.contains('heading-hero')) {
        this.HeadElement.style.marginTop = '0';
    }
    else {
        this.headerComp = this.componentHost.closest('header');
        this.headerHeight = this.headerComp.offsetHeight;
        this.marginTop;
        if(window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this.marginTop = 96;
        }
        else if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
            this.marginTop = 80;
        }
        else if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.sm) {
            this.marginTop = 64;
        }
        this.HeadElement.style.marginTop = `${this.marginTop}px`;
        this.HeadElement.style.marginTop = `${this.headerHeight + this.marginTop}px`;
    }
    this.aceImage = this.HeadElement.querySelector(".ace-image");
    this.headerComp = this.componentHost.closest('header');
   this.myDiv = this.componentHost.querySelector('.ace-header-navigation__main-container.--jsfied');
    this.componentHost.querySelector('#ace-burger-menu')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        if (this.myDiv.classList.contains('show')) {
            document.querySelector('.ace-quick-access-component-v2 .cmp-filter')?.classList?.add('mob-sticky');
        }
        else {
            document.querySelector('.ace-quick-access-component-v2 .cmp-filter')?.classList?.remove('mob-sticky');
        }
    });
    this.headerBlur();
   
}

    headerBlur() {
        if(this.aceImage?.getAttribute('data-loaded') == 'true' || this.HeadElement.querySelector(".content-wrapper")){
            this.headerComp.classList.add('HeaderInverse');
            window.addEventListener(CoreJS.DomEventConstants.SCROLL, () => {
                if(this.HeadElement.getBoundingClientRect().bottom <= 0){
                    this.headerComp.classList.remove('HeaderInverse');
                }
                else{
                    this.headerComp.classList.add('HeaderInverse');
                }
            })
        }
        
    }

}
// Register the NavBar component with the BaseComponent
CoreJS.BaseComponent.registerComponent(HeaderNavigation.CLASS_NAMESPACE, MGalleryHeaderNavigation, true);