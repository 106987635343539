/* eslint-disable no-unused-expressions */
/**
 * Class representing a Mgallery Card editorial Component instance
 * @author Rishabh
 * @class MGalleryCardEditorialSlideshow
 * @classdesc Mgallery Card editorial component to inherit the callout core component
 * @extends CardEditorialSlideshow
 * @version 1.0
 */
import { CardEditorialSlideshow } from '@accor/ace-ui-core';

export default class MGalleryCardEditorialSlideshow extends CardEditorialSlideshow {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      this.pushDataTagmanager();
    }
  }

  pushDataTagmanager() {
    let blockName = 'offers';
    let blockInteract = 'see offer page';
    if (this.dataLayerJson?.pageName === 'mgallery::offers::homepage') { // Offers homepage
        blockName = 'highlighted offer';
        blockInteract = 'see offer details';
    }
    this.componentHost.querySelectorAll('.cmp-slideshow__callouts .cmp-slideshow__callout-item a')?.forEach((anchor) => {
      anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this.dataEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.dataLayerJson?.pageName,
            bloc_name: blockName,
            bloc_interaction: blockInteract,
            bloc_type: this.componentHost?.querySelector('.cmp-slideshow__callout-title')?.innerText?.trim()?.toLowerCase()
          }
        };
        TagManager.trackEvent(this.dataEvent);
      });
    });
  }
}


// Register the Mgallery card editorial component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(CardEditorialSlideshow.CLASS_NAMESPACE, MGalleryCardEditorialSlideshow, true);
