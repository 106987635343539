import { TagManager } from '@accor/ace-ui-core';

export default class SpecialTouches extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-special-touches';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();

    // We don't want to enable all JS in edit to facilitate contribution of component
    if (!this.componentHost.classList.contains('edit-mode')) {
      this.activeVideo = null;
      this.activeElement = null;

      this.componentHost.querySelectorAll('.fake-content-wrapper .content-column').forEach((element) => {
        this.bindItemEventsDependingOnBreakpoints(element);
      });

      this.bindGlobalEventDependingOnBreakpoints();
      document.dispatchEvent(new CustomEvent('loadvideoevent'));

      this.activateElement(this.componentHost.querySelector('.fake-content-wrapper .content-column'));
    }

    this._mediaContainerVal = this.componentHost.querySelector('.content-background-media');
    this._colContainerVal = this.componentHost.querySelector('.content-wrapper');

    const containerImage = this.componentHost.querySelectorAll('.image-container');
    const containerTarget = this.componentHost.querySelectorAll('.js-content-container');

    const isImage = containerImage.length !== 0;

    if (isImage) {
      for (let i = 0; i < containerImage.length; i++) {
        containerTarget[i].appendChild(containerImage[i].querySelector('.ace-image'));
        // set dynamic height in case of image media type on component
        this.componentHost?.classList?.add('has-image');
      }
    }

    if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.mobileSwipeEvent();
    }

    this.checkForActiveItemMediaType();
  }

  mobileSwipeEvent() {
    this._active = 1;
    const total = this.componentHost.querySelectorAll('.content-viewer-component').length;
    const controls = this.componentHost.querySelector('.special-touches-actions');
    const previousButton = controls.querySelector('.left');
    const nextButton = controls.querySelector('.right');
    const paginationDisplaySwipe = controls.querySelector('.counter');

    paginationDisplaySwipe.innerText = `${this._active} / ${total}`;
    previousButton.disabled = true;

    const thresholdDefinition = {
      threshold: 0.6
    };
    this.specialTouchObserver = new IntersectionObserver((item) => {
      item.forEach((entry) => {
        if (entry.isIntersecting) {
          if (window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
            this._active = parseInt(entry.target.getAttribute('display-id')) + 1;
            if (this._active === 1) previousButton.disabled = true;
            if (this._active < total) nextButton.disabled = null;
            if (this._active > 1) previousButton.disabled = null;
            if (this._active === total) nextButton.disabled = true;
            paginationDisplaySwipe.innerText = `${this._active} / ${total}`;
            if (this._active !== total) this.deactivateElement(this.componentHost.querySelector(`.content-column[data-number='${this._active + 1}']`));
            if ( this._active !== 1) this.deactivateElement(this.componentHost.querySelector(`.content-column[data-number='${this._active - 1}']`));
            this.activateElement(this.componentHost.querySelector(`.content-column[data-number='${this._active}']`));
          }
        }
      });
    }, thresholdDefinition);
    const itemList = this._colContainerVal.querySelectorAll('.content-column');
    itemList?.forEach((item, index) => {
      item.setAttribute('display-id', index);
      this.specialTouchObserver?.observe(item);
      if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        item?.querySelector('.special-touches-item a')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
          if (dataLayerValue) {
            this.dataLayerJson = JSON.parse(dataLayerValue);
            this.tagManagerhandler(item?.querySelector('.special-touches-item a'));
          }
        });
      }
    });
  }

  /**
     * Binding Events which are global to component. It's mainly to add controls of the carousel view in tablet
     * and mobile.
    */
  bindGlobalEventDependingOnBreakpoints() {
    if (window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      // Do something on desktop, but nothing for now
    } else {
      const controls = this.componentHost.querySelector('.special-touches-actions');
      const previousButton = controls.querySelector('.left');
      const nextButton = controls.querySelector('.right');

      previousButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this._mediaContainerVal.scrollLeft -= this._mediaContainerVal.querySelector('.media-container')?.offsetWidth;
        this._colContainerVal.scrollLeft -= this._colContainerVal.querySelector('.content-column')?.offsetWidth;
      });

      nextButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this._mediaContainerVal.scrollLeft += this._mediaContainerVal.querySelector('.media-container')?.offsetWidth;
        this._colContainerVal.scrollLeft += this._colContainerVal.querySelector('.content-column')?.offsetWidth;
      });
    }
  }

  /**
     * Events which are specific to each Special touches items. It's mainly handling desktop view when user interacts
     * independently on one column.
     * @param {HTMLElement} htmlElement - Current item in iteration
     */
  bindItemEventsDependingOnBreakpoints(htmlElement) {
    // We retrieved the default video active.
    const associatedVideo = this.componentHost.querySelector(`.content-background-media div.content-${htmlElement.dataset.number}`);
    if (htmlElement.classList.contains('active')) {
      this.activeVideo = associatedVideo;
    }

    const associatedColumn = this.componentHost.querySelector(`.associated-item-${htmlElement.dataset.number}`);
    const specialTouchItem = associatedColumn.querySelector('.special-touches-item');
    specialTouchItem.dataset.originalHeight = specialTouchItem.clientHeight;
    const showHideContent = associatedColumn.querySelector('.show-hide-content');

    // Binding Event for Desktop view
    if (window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      // We need to hide text column height by JS to be able to retrieve its original height for the rollover effect.
      showHideContent.classList.add('no-height');

      [CoreJS.DomEventConstants.MOUSE_OVER, CoreJS.DomEventConstants.FOCUS_IN].forEach((event) => {
        htmlElement.addEventListener(event, (overEvent) => {
        // On first mouseover event, we change the whole layout only once
          this.activateElement(overEvent.currentTarget);
        });
      });


      const isNotImage = this.componentHost.querySelectorAll('.image-container').length == 0;

      if (isNotImage) {
        htmlElement.addEventListener(CoreJS.DomEventConstants.MOUSE_LEAVE, () => {
          this.activeVideo.querySelector('video').pause();
        });
      }
    }
    // Binding click event on fake content wrapper, to make the whole column that can be clicked instead of just the CTA link.
    const link = showHideContent.querySelector('a.link');
    if (link) {
      htmlElement.style.cursor = 'pointer';
    }

    [CoreJS.DomEventConstants.CLICK, CoreJS.DomEventConstants.TOUCH_END].forEach((eventType) => {
      htmlElement.addEventListener(eventType, () => {
        window.open(link.dataset.link, '_self');
        const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
        if (dataLayerValue) {
          this.dataLayerJson = JSON.parse(dataLayerValue);
          this.tagManagerhandler(link);
        }
      });
    });

    // We now fill all Dynamic Media Video tags with correct Scene7 values.
    this.populateVideoDataAttributesAndLoad(specialTouchItem, associatedVideo);
  }

  /**
     * Deactivate view of an HTML element card of the component
     * @param {HTMLElement} sourceElement -
     */
  deactivateElement(sourceElement) {
    const associatedColumn = this.componentHost.querySelector(`.associated-item-${sourceElement.dataset.number}`);
    const showHideContent = associatedColumn.querySelector('.show-hide-content');
    const comingVideo = this.componentHost.querySelector(`.content-background-media div.content-${sourceElement.dataset.number}`);

    if (window.videoViewers) {
      const associatedViewer = window.videoViewers.find((viewer) => viewer.containerId === comingVideo.id);
      if (associatedViewer && comingVideo.querySelector('video')) {
        associatedViewer.videoplayer.pause();
      }
    }

    showHideContent.classList.add('no-height');
    showHideContent.style.height = null;
  }

  /**
     * Activate view of an HTML element card of the component
     * @param {HTMLElement} sourceElement - The Fake column handling
     * @param {Boolean} playVideo -
     */
  activateElement(sourceElement, playVideo = true) {
    if (this.activeElement) {
      this.deactivateElement(this.activeElement);
    }

    this.activeElement = sourceElement;
    this.componentHost.querySelector('.content-column.active').classList.remove('active');
    const associatedColumn = this.componentHost.querySelector(`.associated-item-${sourceElement.dataset.number}`);
    associatedColumn.classList.add('active');
    const showHideContent = associatedColumn.querySelector('.show-hide-content');
    const specialTouchItem = associatedColumn.querySelector('.special-touches-item');

    // In desktop view, for animating text, we need to set height by code.
    if (window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      //showHideContent.style.height = `${specialTouchItem.dataset.originalHeight}px`;
    }

    const comingVideo = this.componentHost.querySelector(`.content-background-media div.content-${sourceElement.dataset.number}`);

    // Toggling 'hidden' class on video holder will trigger automatically CSS transition on opacity.
    this.activeVideo.classList.add('hidden');
    comingVideo.classList.remove('hidden');

    if (playVideo && window.videoViewers) {
      const associatedViewer = window.videoViewers.find((viewer) => viewer.containerId === comingVideo.id);
      if (associatedViewer && comingVideo.querySelector('video')) {
        //  To allow starting play by code, we need to mute video
        comingVideo.querySelector('video').muted = 'muted';
        comingVideo.querySelector('video').setAttribute('muted', '');
        comingVideo.querySelector('video').setAttribute('playsinline', '');
        associatedViewer.videoplayer.play();
      }
    }
    this.activeVideo = comingVideo;

    // Once activated check for media type
    this.checkForActiveItemMediaType();
  }

  /**
     * Method making travel original video dataset attributes from column items, to corresponding
     * dynamic media video component.
     * @param {HTMLElement} sourceElement - Source Element carrying video data attributes
     * @param {HTMLElement} targetElement - Target Element Dynamic Media video component using S7 sdk.
     */
  populateVideoDataAttributesAndLoad(sourceElement, targetElement) {
    targetElement.dataset.imageServerUrl = sourceElement.dataset.imageServerUrl;
    targetElement.dataset.videoServerUrl = sourceElement.dataset.videoServerUrl;
    targetElement.dataset.presetPath = sourceElement.dataset.presetPath;
    targetElement.dataset.assetId = sourceElement.dataset.assetId;
    targetElement.classList.remove('deferred');
  }

  /**
     * push ga events for clickable cards in tag manager for all devices
     * check if card is clickable if yes add event handler for corresponding fake wrapper element
     * @param {HTMLElement} link - Target Element for tag manager event
     */
  tagManagerhandler(link) {
    const dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: 'highlighted memorable',
        bloc_interaction: `see ${link?.closest('.special-touches-item')?.querySelector('h2')?.innerText?.trim()?.toLowerCase() || ''}`
      }
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
     * check media type of active item
     * Based on media type, add class on overlay to set opacity
     */
  checkForActiveItemMediaType() {
    const activeElement = this.componentHost?.querySelector('.content-column.active');
    if (activeElement?.querySelector('.image-container')) {
      this.componentHost?.querySelector('.background-overlays')?.classList?.add('image-overlay');
    } else {
      this.componentHost?.querySelector('.background-overlays')?.classList?.remove('image-overlay');
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(SpecialTouches.CLASS_NAMESPACE, SpecialTouches);
